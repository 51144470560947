<template>
  <SignInLayout
    :subTitle="
      isCompleted
        ? this.$t('auth.resetPasswordSendMessage', { email })
        : this.$t('auth.resetPasswordSubtitle', { brandName })
    "
    :title="this.$t('auth.resetPasswordTitle')"
  >
    <div class="request-reset-password">
      <template v-if="!isCompleted">
        <form novalidate v-on:submit.prevent="submit">
          <BaseInput
            v-model="email"
            :errors="emailErrors"
            :fullWidth="true"
            :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
            :placeholder="$t('general.inputs.email')"
            fieldName="email"
            outlined
            required
            @blur="$v.email.$touch()"
            @change="onInputChange"
          />
          <PrimaryButton
            :disabled="isButtonDisabled"
            :loading="isLoading"
            :text="$t('auth.sendPasswordEmail')"
            class="request-reset-password__submit-btn"
            size="x-large"
            type="submit"
          />
          <span class="request-reset-password_link mt-8" @click="backToLogin">{{ $t("auth.backToLogin") }}</span>
        </form>
      </template>
      <template v-else>
        <PrimaryButton
          :text="$t('auth.backToLogin')"
          class="request-reset-password__submit-btn"
          size="x-large"
          type="submit"
          @onClick="backToLogin"
        />
        <span class="request-reset-password_link mt-8" @click="toggleIsCompleted">{{ $t("general.tryAgain") }}</span>
      </template>
    </div>
  </SignInLayout>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

import { AuthApi } from "@/api/auth";
import SignInLayout from "@/components/SignInLayout";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "RequestResetPassword",
  components: {
    SignInLayout,
    BaseInput,
    PrimaryButton,
  },
  validations: {
    email: { required, email },
  },
  data() {
    return {
      isLoading: false,
      isCompleted: false,
      email: "",
      brandName: process.env.VUE_APP_BRAND_NAME,
    };
  },
  computed: {
    isButtonDisabled() {
      return !this.email || Boolean(this.emailErrors.length);
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const response = await AuthApi.requestRecoverPassword(this.email);
        if (response.status) {
          this.isCompleted = !this.isCompleted;
          this.$v.$reset();
        }
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Something went wrong while saving the new password.",
          type: "error",
        });
        this.snackbar = true;
        this.error = err.message;
      }
      this.isLoading = false;
    },
    toggleIsCompleted() {
      this.email = "";
      this.isCompleted = !this.isCompleted;
    },
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },
    async backToLogin() {
      await this.$router.push({ name: routesEnum.signin });
    },
  },
};
</script>

<style lang="scss">
.request-reset-password {
  .v-input {
    margin-bottom: 20px;
  }

  &__submit-btn {
    text-transform: none;

    .v-btn__content {
      text-transform: none;
    }
  }

  text-align: center;
  width: 440px;
  margin: 10px auto;

  @media (max-width: 767.98px) {
    width: auto;
    margin: 10px 20px;
  }

  &_link {
    cursor: pointer;
    display: inline-block;
    color: $primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
</style>
